@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

body {
  font-family: 'Manrope', sans-serif;
  /* background-color: #e5e7eb; */
  transition: background-color 1s ease;
}

.bg-black {
  background-color: black !important;
}

.no-scroll {
  overflow-y: hidden;
  overflow-x: auto;
  height: 100vh;
}

  /* Customize the scrollbar track */
  ::-webkit-scrollbar {
    width: 3px; /* Width of the scrollbar */
    display: none;
  }

  ::-webkit-scrollbar-track {
    background: #e5e7eb; /* Track color */
    border-radius: 10px; /* Rounded corners for the track */
  }

  ::-webkit-scrollbar-thumb {
    background: #000000; /* Thumb color */
    border-radius: 10px; /* Rounded corners for the thumb */
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555; /* Thumb color on hover */
  }

  